<template>
  <div
    class="bg-white dark:bg-black border border-neutral-300 dark:border-neutral-700 p-2 rounded-xl flex justify-between items-center"
  >
    <div v-if="emote" class="flex items-center gap-2">
      <EmoteImage :emote class="w-6 h-6 rounded-md"></EmoteImage>
      <p class="font-medium text-sm">:{{ emote.attributes.key }}:</p>
    </div>
    <div v-if="emote" class="flex items-center gap-2">
      <NamiButton
        button-type="primary"
        pill
        :text="!emoteIsFavorite"
        :icon="emoteIsFavorite ? IconCheck : IconStar"
        small
        no-waves
        :loading="setFavoritePending || removeFavoritePending"
        :force-icon-only="setFavoritePending || removeFavoritePending"
        @button-click="toggleFavorite"
      >
        {{ t("components.EmoteFavoriteBar.favorite") }}
      </NamiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconCheck, IconStar } from "@tabler/icons-vue";
import type {
  EmoteEntity,
  EmoteRelation,
  PopulateRelationship,
} from "~/src/api";

const props = defineProps<{
  emote?: EmoteEntity | PopulateRelationship<EmoteRelation>;
}>();

const emit = defineEmits<{
  (e: "favorite"): void;
  (e: "unfavorite"): void;
}>();

const { t } = useNuxtApp().$i18n.global;

const {
  favoriteEmotesData,
  favoriteEmotesRelationships,
  favoriteEmotesError,
  favoriteEmotesPending,
  favoriteEmotesRefresh,
  setFavorite,
  removeFavorite,
} = useFavoriteEmotes();

const emoteIsFavorite = computed(() =>
  favoriteEmotesRelationships.value.find((e) => e.id === props.emote?.id),
);

const { pending: setFavoritePending, action: setFavoriteAction } = setFavorite;
const { pending: removeFavoritePending, action: removeFavoriteAction } =
  removeFavorite;

async function toggleFavorite() {
  if (!props.emote) return;

  if (emoteIsFavorite.value) {
    await removeFavoriteAction(props.emote);
    emit("unfavorite");
  } else {
    await setFavoriteAction(props.emote);
    emit("favorite");
  }
}
</script>
