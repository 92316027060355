<template>
  <AsyncState
    :data="availableEmotesData"
    :pending="availableEmotesPending"
    :error="availableEmotesError"
    :refresh="availableEmotesRefresh"
  >
    <template #default="{ data: emotesByOrg, attrs }">
      <div
        class="bg-neutral-100 dark:bg-neutral-900 rounded-md px-2 relative w-full grid grid-rows-[2.5rem_2.5rem_1fr_2.5rem] gap-2 *:min-w-0"
        v-bind="attrs"
      >
        <div class="border-b border-neutral-300 dark:border-neutral-700">
          <Tabs
            v-if="emotesByOrg"
            v-model="currentTab"
            :tabs="tabs"
            ref="tabsComponent"
          ></Tabs>
        </div>
        <div class="flex items-center gap-2">
          <input
            type="text"
            class="no-focus w-full p-2 rounded-md bg-neutral-200 dark:bg-neutral-800 border-none"
            :placeholder="
              currentTab === 'emotes'
                ? 'Search for emotes'
                : currentTab === 'stickers'
                  ? 'Search for stickers'
                  : 'Search for GIFs'
            "
            :value="searchText"
            @input="(e) => (searchText = (e.target as HTMLInputElement).value)"
          />
          <template v-if="currentTab === 'emotes'">
            <Tooltip
              variant="custom"
              fixed
              no-pointer-events
              size="large"
              class="w-6 h-6 mx-auto"
            >
              <template #default>
                <button
                  :style="{
                    backgroundColor: skinToneColors[selectedSkinTone ?? 'none'],
                  }"
                  class="w-6 h-6 rounded-md"
                ></button>
              </template>
              <template #tooltip="{ hide }">
                <div
                  class="pointer-events-auto inline-block mx-auto bg-white dark:bg-black rounded-md p-2 border border-neutral-400 dark:border-neutral-600"
                >
                  <ul class="flex gap-1 h-6">
                    <li v-for="tone in Object.keys(skinToneColors)">
                      <button
                        :style="{
                          backgroundColor:
                            skinToneColors[tone as keyof typeof skinToneColors],
                        }"
                        class="w-6 h-6 rounded-md transition-transform hover:scale-110"
                        @click.stop="
                          () => {
                            selectedSkinTone =
                              tone === 'none' ? null : (tone as EmojiSkinTone);
                            hide();
                          }
                        "
                      ></button>
                    </li>
                  </ul>
                </div>
              </template>
            </Tooltip>
          </template>
        </div>
        <div
          v-if="currentTab !== 'gifs'"
          class="grid grid-cols-[2.5rem_1fr] gap-4 min-h-0"
        >
          <div
            class="overflow-y-auto overflow-x-hidden space-y-4 py-4 pr-4 mx-auto relative z-[1]"
          >
            <button
              v-if="
                currentTab === 'emotes'
                  ? filteredFavoriteEmotes.length > 0
                  : filteredFavoriteStickers.length > 0
              "
              class="group relative"
              @click="scrollToTop"
            >
              <IconStar class="w-6 h-6" :size="20" />
              <div
                class="absolute -right-2 top-[0.125rem] w-[2px] h-5 rounded-full transition-colors"
                :class="{
                  'group-hover:bg-neutral-300 dark:group-hover:bg-neutral-700':
                    !visibleOrgEmotesInContainer.favorites,
                  'bg-nami-comi-blue': visibleOrgEmotesInContainer.favorites,
                }"
              ></div>
            </button>
            <ul class="space-y-2">
              <template v-for="[orgId, data] in Object.entries(filteredEmotes)">
                <li v-if="data![currentTab].length > 0" class="h-6">
                  <button
                    class="group relative"
                    @click="scrollToOrg(data?.org)"
                  >
                    <AsyncImage
                      v-if="!data!.org"
                      src="/favicon.svg"
                      class="w-6 h-6"
                    ></AsyncImage>
                    <OrganizationAvatar
                      v-else
                      :org="data!.org"
                      :size="24"
                    ></OrganizationAvatar>
                    <div
                      class="absolute -right-2 top-[0.125rem] w-[2px] h-5 rounded-full transition-colors"
                      :class="{
                        'group-hover:bg-neutral-300 dark:group-hover:bg-neutral-700':
                          !visibleOrgEmotesInContainer[orgId],
                        'bg-nami-comi-blue': visibleOrgEmotesInContainer[orgId],
                      }"
                    ></div>
                  </button>
                </li>
              </template>
            </ul>
            <template v-if="currentTab === 'emotes'">
              <div class="h-[1px] bg-neutral-300 dark:bg-neutral-700"></div>
              <ul>
                <template v-for="group in Object.keys(filteredEmoji)">
                  <li v-if="hasEmoji(filteredEmoji[group])">
                    <button
                      class="group relative"
                      @click="scrollToEmojiGroup(group)"
                    >
                      <component
                        :is="emojiGroupIconMap[group]"
                        class="w-6 h-6"
                        :size="20"
                      ></component>
                      <div
                        class="absolute -right-2 top-[0.125rem] w-[2px] h-5 rounded-full transition-colors"
                        :class="{
                          'group-hover:bg-neutral-300 dark:group-hover:bg-neutral-700':
                            !visibleEmojiGroupsInContainer[group],
                          'bg-nami-comi-blue':
                            visibleEmojiGroupsInContainer[group],
                        }"
                      ></div>
                    </button>
                  </li>
                </template>
              </ul>
            </template>
          </div>
          <div class="overflow-y-auto overflow-x-hidden" ref="emotesContainer">
            <template v-if="currentTab === 'emotes'">
              <div v-if="filteredFavoriteEmotes.length > 0" class="favorites">
                <div
                  class="pt-4 pb-2 sticky top-0 bg-neutral-100 dark:bg-neutral-900"
                  :style="{
                    zIndex: 0,
                  }"
                >
                  <p class="text-nami-comi-blue">
                    {{ t("components.EmoteComposer.favorites") }}
                  </p>
                  <div class="h-[1px] bg-neutral-300 dark:bg-neutral-700"></div>
                </div>
                <ul class="flex flex-wrap">
                  <li
                    v-for="emote in filteredFavoriteEmotes"
                    :key="`favorite-${emote.id}`"
                  >
                    <EmoteComposerEmote
                      :emote
                      @select="$emit('selectEmote', emote)"
                      @focus="
                        hoveredEmote = {
                          type: 'emote',
                          emote: emote,
                          org: findOrgOfEmoteInData(emote),
                        }
                      "
                      @mouseenter="
                        hoveredEmote = {
                          type: 'emote',
                          emote: emote,
                          org: findOrgOfEmoteInData(emote),
                        }
                      "
                      @contextmenu="
                        (e: PointerEvent) => {
                          e.preventDefault();
                          selectedEmoteForOptions = emote;
                          emoteOptionsVisible = true;
                        }
                      "
                    ></EmoteComposerEmote>
                  </li>
                </ul>
              </div>
              <ul
                ref="orgEmotesList"
                :class="{ disabled: disabledTypes?.includes('emotes') }"
              >
                <template v-for="(orgId, index) in Object.keys(filteredEmotes)">
                  <li
                    v-if="filteredEmotes[orgId]!.emotes.length > 0"
                    :org-id="orgId"
                  >
                    <div
                      class="pt-4 pb-2 sticky top-0 bg-neutral-100 dark:bg-neutral-900"
                      :style="{
                        zIndex: totalGroupsInAvailableEmotes + index + 1,
                      }"
                    >
                      <p>
                        {{
                          filteredEmotes[orgId]!.org?.attributes.name ?? orgId
                        }}
                      </p>
                      <div
                        class="h-[1px] bg-neutral-300 dark:bg-neutral-700"
                      ></div>
                    </div>
                    <ul class="flex flex-wrap">
                      <li v-for="emote in filteredEmotes[orgId]!.emotes">
                        <EmoteComposerEmote
                          :emote
                          @select="$emit('selectEmote', emote)"
                          @focus="
                            hoveredEmote = {
                              type: 'emote',
                              emote: emote,
                              org: filteredEmotes[orgId]?.org,
                            }
                          "
                          @mouseenter="
                            hoveredEmote = {
                              type: 'emote',
                              emote: emote,
                              org: filteredEmotes[orgId]?.org,
                            }
                          "
                          @contextmenu="
                            (e: PointerEvent) => {
                              e.preventDefault();
                              selectedEmoteForOptions = emote;
                              emoteOptionsVisible = true;
                            }
                          "
                        ></EmoteComposerEmote>
                      </li>
                    </ul>
                  </li>
                </template>
              </ul>
              <ul ref="emojiList">
                <template v-for="(group, index) in Object.keys(filteredEmoji)">
                  <li
                    v-if="hasEmoji(filteredEmoji[group])"
                    :emoji-group="simpleKebab(group)"
                  >
                    <div
                      class="pt-4 pb-2 sticky top-0 bg-neutral-100 dark:bg-neutral-900"
                      :style="{
                        zIndex: totalGroupsInAvailableEmotes + index + 1,
                      }"
                    >
                      <p>{{ group }}</p>
                      <div
                        class="h-[1px] bg-neutral-300 dark:bg-neutral-700"
                      ></div>
                    </div>
                    <ul class="flex flex-wrap">
                      <li v-for="emoji in filteredEmoji[group].emoji">
                        <EmoteComposerOsEmote
                          :name="emoji.name"
                          :character="emoji.character"
                          @select="$emit('selectEmoji', emoji.character)"
                          @focus="
                            hoveredEmote = {
                              type: 'emoji',
                              name: emoji.name,
                              character: emoji.character,
                            }
                          "
                          @mouseenter="
                            hoveredEmote = {
                              type: 'emoji',
                              name: emoji.name,
                              character: emoji.character,
                            }
                          "
                        ></EmoteComposerOsEmote>
                      </li>
                      <template
                        v-for="subgroup in Object.keys(
                          filteredEmoji[group].subgroups,
                        )"
                      >
                        <li
                          v-for="emoji in filteredEmoji[group].subgroups[
                            subgroup
                          ].emoji"
                        >
                          <EmoteComposerOsEmote
                            :name="emoji.name"
                            :character="emoji.character"
                            @select="$emit('selectEmoji', emoji.character)"
                            @focus="
                              hoveredEmote = {
                                type: 'emoji',
                                name: emoji.name,
                                character: emoji.character,
                              }
                            "
                            @mouseenter="
                              hoveredEmote = {
                                type: 'emoji',
                                name: emoji.name,
                                character: emoji.character,
                              }
                            "
                          ></EmoteComposerOsEmote>
                        </li>
                      </template>
                    </ul>
                  </li>
                </template>
              </ul>
            </template>
            <template v-else>
              <div v-if="filteredFavoriteStickers.length > 0" class="favorites">
                <div
                  class="pt-4 pb-2 sticky top-0 bg-neutral-100 dark:bg-neutral-900"
                  :style="{
                    zIndex: 0,
                  }"
                >
                  <p class="text-nami-comi-blue">
                    {{ t("components.EmoteComposer.favorites") }}
                  </p>
                  <div class="h-[1px] bg-neutral-300 dark:bg-neutral-700"></div>
                </div>
                <ul class="grid grid-cols-3 gap-x-4 gap-y-6">
                  <li
                    v-for="emote in filteredFavoriteStickers"
                    :key="`favorite-${emote.id}`"
                    class="flex flex-col items-center"
                  >
                    <EmoteComposerEmote
                      :emote
                      @select="$emit('selectSticker', emote)"
                      @focus="
                        hoveredEmote = {
                          type: 'emote',
                          emote: emote,
                          org: findOrgOfEmoteInData(emote),
                        }
                      "
                      @mouseenter="
                        hoveredEmote = {
                          type: 'emote',
                          emote: emote,
                          org: findOrgOfEmoteInData(emote),
                        }
                      "
                      @contextmenu="
                        (e: PointerEvent) => {
                          e.preventDefault();
                          selectedEmoteForOptions = emote;
                          emoteOptionsVisible = true;
                        }
                      "
                    ></EmoteComposerEmote>
                  </li>
                </ul>
              </div>
              <ul
                ref="orgEmotesList"
                :class="{ disabled: disabledTypes?.includes('stickers') }"
              >
                <template v-for="(orgId, index) in Object.keys(filteredEmotes)">
                  <li
                    v-if="filteredEmotes[orgId]!.stickers.length > 0"
                    :org-id="orgId"
                  >
                    <div
                      class="pt-4 pb-2 sticky top-0 bg-neutral-100 dark:bg-neutral-900"
                      :style="{
                        zIndex: totalGroupsInAvailableStickers + index + 1,
                      }"
                    >
                      <p>
                        {{
                          filteredEmotes[orgId]!.org?.attributes.name ?? orgId
                        }}
                      </p>
                      <div
                        class="h-[1px] bg-neutral-300 dark:bg-neutral-700"
                      ></div>
                    </div>
                    <ul class="grid grid-cols-3 gap-x-4 gap-y-6">
                      <li
                        v-for="sticker in filteredEmotes[orgId]!.stickers"
                        class="flex flex-col items-center"
                      >
                        <EmoteComposerEmote
                          :emote="sticker"
                          @select="$emit('selectSticker', sticker)"
                          @focus="
                            hoveredEmote = {
                              type: 'emote',
                              emote: sticker,
                              org: filteredEmotes[orgId]?.org,
                            }
                          "
                          @mouseenter="
                            hoveredEmote = {
                              type: 'emote',
                              emote: sticker,
                              org: filteredEmotes[orgId]?.org,
                            }
                          "
                          @contextmenu="
                            (e: PointerEvent) => {
                              e.preventDefault();
                              selectedEmoteForOptions = sticker;
                              emoteOptionsVisible = true;
                            }
                          "
                        ></EmoteComposerEmote>
                      </li>
                    </ul>
                  </li>
                </template>
              </ul>
            </template>
          </div>
        </div>
        <div v-else class="min-h-0 overflow-y-auto overflow-x-hidden my-auto">
          <div class="flex flex-col justify-center items-center">
            <AsyncImage
              :src="getAbsoluteAssetLink(`nami/stickers/wink.png`)"
              class="h-32 w-32"
            ></AsyncImage>
            <p class="text-center">Coming soon!</p>
          </div>
        </div>
        <div
          class="bg-neutral-100 dark:bg-neutral-900 border-t border-neutral-300 dark:border-neutral-700 grid grid-cols-2 items-center"
        >
          <template v-if="hoveredEmote?.type === 'emote'">
            <div class="flex items-center gap-2">
              <EmoteImage
                :emote="hoveredEmote.emote"
                class="w-6 h-6 rounded-md"
              ></EmoteImage>
              <p class="font-medium text-sm">
                :{{ hoveredEmote.emote.attributes.key }}:
              </p>
            </div>
            <component
              :is="hoveredEmote.org ? TheNuxtLink : 'div'"
              :to="hoveredEmote.org ? linkTo(hoveredEmote.org) : undefined"
              class="flex justify-end items-center gap-2"
            >
              <p class="text-sm">
                {{ hoveredEmote.org?.attributes.name ?? "NamiComi" }}
              </p>
              <OrganizationAvatar
                v-if="hoveredEmote.org"
                :org="hoveredEmote.org"
                :size="24"
              ></OrganizationAvatar>
              <img v-else src="/favicon.svg" class="w-6 h-6" />
            </component>
          </template>
        </div>
        <EmoteFavoriteBar
          :emote="selectedEmoteForOptions ?? undefined"
          class="absolute z-[11] left-[3rem] bottom-12 w-[calc(100%-4rem)] transition-[opacity,transform] duration-200"
          :class="{
            'translate-y-16 opacity-0 ease-in': !emoteOptionsVisible,
            'translate-y-0 opacity-100 ease-out': emoteOptionsVisible,
          }"
          @favorite="emoteOptionsVisible = false"
          @unfavorite="emoteOptionsVisible = false"
          ref="emoteOptionsRef"
        />
      </div>
    </template>
  </AsyncState>
</template>

<script setup lang="ts">
import {
  useAvailableEmotes,
  useUnicodeEmotes,
  type EmotesByOrg,
} from "~/composables/async/emote";
import {
  UnicodeEmojiParser,
  type EmojiByGroup,
  type UnicodeEmoji,
} from "~/utils/emoji/UnicodeEmojiParser";
import {
  debouncedRef,
  useEventListener,
  onClickOutside,
  type MaybeElementRef,
} from "@vueuse/core";
import {
  IconMoodSmile,
  IconThumbUp,
  IconPaw,
  IconBottle,
  IconPlane,
  IconActivity,
  IconCameraFilled,
  IconLetterA,
  IconFlagFilled,
  IconStar,
} from "@tabler/icons-vue";
import TheNuxtLink from "../the/TheNuxtLink.vue";
import type { EmoteEntity, EmoteRelation } from "~/src/api";
import type { OrganizationRelation } from "~/src/api";
import type { PopulateRelationship } from "~/src/api";
import type { PageTab } from "~/types/navigation";

export type TabValue = "emotes" | "stickers" | "gifs";

export type EmojiSkinTone =
  | "light"
  | "medium-light"
  | "medium"
  | "medium-dark"
  | "dark";

const skinToneColors: Record<EmojiSkinTone | "none", string> = {
  none: "#ffde34",
  light: "#ffdbac",
  "medium-light": "#f1c27d",
  medium: "#e0ac69",
  "medium-dark": "#c68642",
  dark: "#8d5524",
};

const skinToneVariantsIndexOffsets: Record<EmojiSkinTone, number> = {
  light: 1,
  "medium-light": 2,
  medium: 3,
  "medium-dark": 4,
  dark: 5,
};

const emojiGroupIconMap: Record<string, typeof IconMoodSmile> = {
  "Smileys & Emotion": IconMoodSmile,
  "People & Body": IconThumbUp,
  "Animals & Nature": IconPaw,
  "Food & Drink": IconBottle,
  "Travel & Places": IconPlane,
  Activities: IconActivity,
  Objects: IconCameraFilled,
  Symbols: IconLetterA,
  Flags: IconFlagFilled,
};

defineComponent({
  inheritAttrs: false,
});

const props = defineProps<{
  disabledTabs?: ("emotes" | "stickers" | "gifs")[];
  disabledTypes?: ("emotes" | "stickers" | "gifs")[];
}>();

defineEmits<{
  (
    e: "selectEmote",
    emote: EmoteEntity | PopulateRelationship<EmoteRelation>,
  ): void;
  (
    e: "selectSticker",
    emote: EmoteEntity | PopulateRelationship<EmoteRelation>,
  ): void;
  (e: "selectEmoji", emoji: string): void;
}>();

const { t } = useNuxtApp().$i18n.global;

const {
  availableEmotesData,
  availableEmotesError,
  availableEmotesPending,
  availableEmotesRefresh,
} = useAvailableEmotes();

const {
  favoriteEmotesData,
  favoriteEmotesRelationships,
  favoriteEmotesError,
  favoriteEmotesPending,
  favoriteEmotesRefresh,
  setFavorite,
  removeFavorite,
} = useFavoriteEmotes();

const emoteOptionsRef = useTemplateRef("emoteOptionsRef");

const selectedSkinTone = ref<null | EmojiSkinTone>(null);
const searchText = ref("");
const currentTab = ref<TabValue>("emotes");
const emotesContainer = ref<HTMLDivElement>();
const orgEmotesList = ref<HTMLUListElement>();
const emojiList = ref<HTMLUListElement>();
const tabsComponent = ref<any>();
const visibleOrgEmotesInContainer = reactive<
  Record<string, boolean | undefined>
>({});
const visibleEmojiGroupsInContainer = reactive<
  Record<string, boolean | undefined>
>({});
const emoteOptionsVisible = ref(false);
const selectedEmoteForOptions = ref<
  EmoteEntity | PopulateRelationship<EmoteRelation> | null
>(null);

// @ts-expect-error vueuse can't resolve this for some reason...
onClickOutside(emoteOptionsRef, () => (emoteOptionsVisible.value = false));

const hoveredEmote = ref<
  | (
      | {
          type: "emote";
          emote: EmoteEntity | PopulateRelationship<EmoteRelation>;
          org?: PopulateRelationship<OrganizationRelation>;
        }
      | { type: "emoji"; name: string; character: string }
    )
  | null
>(null);

watch([currentTab], () => {
  hoveredEmote.value = null;
});

const debouncedSearchText = debouncedRef(searchText, 10);

const findOrgOfEmoteInData = computed(() => {
  const currentEmotes = availableEmotesData.value;

  return (emote: EmoteRelation) => {
    return Object.values(currentEmotes ?? {}).find((data) => {
      return (
        data?.emotes.some((e) => e.id === emote.id) ||
        data?.stickers.some((e) => e.id === emote.id)
      );
    })?.org;
  };
});

const tabs = computed<PageTab<TabValue>[]>(() => {
  return [
    {
      name: "Emotes",
      value: "emotes",
      disabled: props.disabledTabs?.includes("emotes"),
    },
    {
      name: "Stickers",
      value: "stickers",
      disabled: props.disabledTabs?.includes("stickers"),
    },
    {
      name: "GIFs",
      value: "gifs",
      disabled: props.disabledTabs?.includes("gifs"),
    },
  ];
});

const totalGroupsInAvailableEmotes = computed(() => {
  let total = 0;
  for (const data of Object.values(availableEmotesData.value ?? {})) {
    if (data?.emotes.length) {
      total++;
    }
  }
  return total;
});

const totalGroupsInAvailableStickers = computed(() => {
  let total = 0;
  for (const data of Object.values(availableEmotesData.value ?? {})) {
    if (data?.stickers.length) {
      total++;
    }
  }
  return total;
});

const asyncUnicodeEmotes = useUnicodeEmotes();

const unicodeEmotesPending = computed(
  () => asyncUnicodeEmotes.value.pending.value,
);
const unicodeEmotesData = computed(() => asyncUnicodeEmotes.value.data.value);
const unicodeEmotesError = computed(() => asyncUnicodeEmotes.value.error.value);
const unicodeEmotesRefresh = computed(() => asyncUnicodeEmotes.value.refresh);

const parsedDefaultEmoji = computed(() => {
  const parser = new UnicodeEmojiParser();
  const parsedEmoji = parser.parseFromString(unicodeEmotesData.value ?? "", [
    "263A FE0F", // smiling face
    "2639 FE0F", // frowning-face
  ]);

  Object.values(parsedEmoji).forEach((data) => {
    data.emoji = data.emoji.dedupe((item) => item.name);
    Object.values(data.subgroups).forEach((data) => {
      data.emoji = data.emoji.dedupe((item) => item.name);
    });
  });

  return parsedEmoji;
});

const filteredEmoji = computed(() => {
  const filteredGroup: EmojiByGroup = {};

  for (const [group, data] of Object.entries(parsedDefaultEmoji.value)) {
    filteredGroup[group] = {
      emoji: filterEmojiByQueries(data.emoji),
      subgroups: {},
    };

    for (const [subgroup, subdata] of Object.entries(
      parsedDefaultEmoji.value[group].subgroups,
    )) {
      filteredGroup[group].subgroups[subgroup] = {
        emoji: filterEmojiByQueries(subdata.emoji),
      };
    }
  }

  return filteredGroup;
});

const filteredEmotes = computed(() => {
  if (!availableEmotesData.value) return {};

  const filteredEmoteList: EmotesByOrg = {};

  for (const [key, data] of Object.entries(availableEmotesData.value)) {
    filteredEmoteList[key] = {
      emotes: filterEmotesByQueries(data!.emotes),
      stickers: filterEmotesByQueries(data!.stickers),
      org: data!.org,
    };
  }

  return filteredEmoteList;
});

const filteredFavoriteEmotes = computed(() => {
  if (!favoriteEmotesData.value) return [];

  const filteredEmoteList: PopulateRelationship<EmoteRelation>[] = [];

  for (const emote of favoriteEmotesRelationships.value) {
    if (
      emote.attributes.kind === "emoji" &&
      (emote.attributes.name.match(debouncedSearchText.value) ||
        emote.attributes.key.match(debouncedSearchText.value))
    ) {
      filteredEmoteList.push(emote);
    }
  }

  return filteredEmoteList;
});

const filteredFavoriteStickers = computed(() => {
  if (!favoriteEmotesData.value) return [];

  const filteredEmoteList: PopulateRelationship<EmoteRelation>[] = [];

  for (const emote of favoriteEmotesRelationships.value) {
    if (
      emote.attributes.kind === "sticker" &&
      (emote.attributes.name.match(debouncedSearchText.value) ||
        emote.attributes.key.match(debouncedSearchText.value))
    ) {
      filteredEmoteList.push(emote);
    }
  }

  return filteredEmoteList;
});

function filterEmojiByQueries(emojiList: UnicodeEmoji[]) {
  const filteredEmoji: UnicodeEmoji[] = [];

  for (let i = 0; i < emojiList.length; ++i) {
    const emoji = emojiList[i];
    if (
      debouncedSearchText.value &&
      !emoji.name.match(debouncedSearchText.value)
    )
      continue;

    const nextEmoji = emojiList.at(i + 1);
    const hasColorVariants = nextEmoji && nextEmoji.name.endsWith("skin-tone");

    if (hasColorVariants) {
      const indexOffset =
        selectedSkinTone.value === null
          ? 0
          : skinToneVariantsIndexOffsets[selectedSkinTone.value];
      filteredEmoji.push(emojiList[i + indexOffset]);
      i += 5;
    } else {
      filteredEmoji.push(emoji);
    }
  }

  return filteredEmoji;
}

function filterEmotesByQueries(emoteList: EmoteEntity[]) {
  if (!debouncedSearchText.value) return emoteList;

  const filteredEmotes: EmoteEntity[] = [];

  for (let i = 0; i < emoteList.length; ++i) {
    const emote = emoteList[i];
    if (
      emote.attributes.name.match(debouncedSearchText.value) ||
      emote.attributes.key.match(debouncedSearchText.value)
    ) {
      filteredEmotes.push(emote);
    }
  }

  return filteredEmotes;
}

function hasEmoji(group: EmojiByGroup[string]) {
  return (
    group.emoji.length > 0 ||
    Object.values(group.subgroups).some(({ emoji }) => emoji.length > 0)
  );
}

function scrollToTop() {
  emotesContainer.value?.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

function scrollToOrg(org: OrganizationRelation | undefined) {
  const groupTop =
    orgEmotesList.value
      ?.querySelector(`[org-id="${org?.id ?? "NamiComi"}"]`)
      ?.getBoundingClientRect().top ?? 0;

  const containerTop = emotesContainer.value?.getBoundingClientRect().top ?? 0;

  const diff = groupTop - containerTop;

  emotesContainer.value?.scrollBy({
    behavior: "smooth",
    top: diff,
    left: 0,
  });
}

function scrollToEmojiGroup(group: string) {
  const groupTop =
    emojiList.value
      ?.querySelector(`[emoji-group="${simpleKebab(group)}"]`)
      ?.getBoundingClientRect().top ?? 0;

  const containerTop = emotesContainer.value?.getBoundingClientRect().top ?? 0;

  const diff = groupTop - containerTop;

  emotesContainer.value?.scrollBy({
    behavior: "smooth",
    top: diff,
    left: 0,
  });
}

function simpleKebab(val: string) {
  return val
    .toLowerCase()
    .replaceAll(" ", "-")
    .split("")
    .filter(
      (char) =>
        (char.charCodeAt(0) >= 96 && char.charCodeAt(0) <= 122) ||
        char.charCodeAt(0) === 45,
    )
    .join("");
}

function onContainerScroll() {
  if (
    !unicodeEmotesData.value ||
    !emotesContainer.value ||
    !orgEmotesList.value ||
    !emojiList.value
  )
    return;

  const containerRect = emotesContainer.value.getBoundingClientRect();

  const scrollCursorTop = containerRect.top;
  const scrollCursorBottom = scrollCursorTop + containerRect.height;

  const favoriteListElement = emotesContainer.value.querySelector(".favorites");
  const favoriteRect = favoriteListElement?.getBoundingClientRect();
  const favoriteTop = favoriteRect?.top ?? 0;
  const favoriteBottom = favoriteTop + (favoriteRect?.height ?? 0);

  if (
    (favoriteTop <= scrollCursorTop && favoriteBottom >= scrollCursorTop) ||
    (favoriteTop >= scrollCursorTop && favoriteBottom <= scrollCursorBottom)
  ) {
    visibleOrgEmotesInContainer["favorites"] = true;
  } else {
    visibleOrgEmotesInContainer["favorites"] = false;
  }

  for (const orgId of Object.keys(filteredEmotes.value)) {
    const listElement = orgEmotesList.value?.querySelector(
      `[org-id="${orgId}"]`,
    );
    if (!listElement) return;

    const top = listElement.getBoundingClientRect().top;
    const bottom = top + listElement.clientHeight;

    if (
      (top <= scrollCursorTop && bottom >= scrollCursorTop) ||
      (top >= scrollCursorTop && bottom <= scrollCursorBottom)
    ) {
      visibleOrgEmotesInContainer[orgId] = true;
    } else {
      visibleOrgEmotesInContainer[orgId] = false;
    }
  }

  for (const group of Object.keys(filteredEmoji.value)) {
    const kebabGroup = simpleKebab(group);

    const listElement = emojiList.value?.querySelector(
      `[emoji-group=${kebabGroup}]`,
    );
    if (!listElement) return;

    const top = listElement.getBoundingClientRect().top;
    const bottom = top + listElement.clientHeight;

    if (
      (top <= scrollCursorTop && bottom >= scrollCursorTop) ||
      (top >= scrollCursorTop && bottom <= scrollCursorBottom)
    ) {
      visibleEmojiGroupsInContainer[group] = true;
    } else {
      visibleEmojiGroupsInContainer[group] = false;
    }
  }
}

useEventListener(
  emotesContainer,
  "scroll",
  () => {
    onContainerScroll();
  },
  { passive: true },
);

const cleanup = watchEffect(() => {
  if (!emotesContainer.value) return;
  onContainerScroll();
  cleanup();
});

defineExpose({
  updateTabsHighlightPosition: () =>
    tabsComponent.value?.updateHighlightPosition(),
});

onMounted(() => {
  onContainerScroll();
});

onUpdated(() => {
  onContainerScroll();
});
</script>

<style>
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
</style>
