import { createAsyncResource } from "./asyncResource";
import {
  Emote,
  isExpanded,
  isOrg,
  isEmote,
  type EmojiKind,
  type EmoteEntity,
  type OrganizationRelation,
  type PopulateRelationship,
  type EmoteFavoriteEntity,
  type EmoteRelation,
} from "~/src/api";

export type OrgEmoteStickerList = {
  emotes: EmoteEntity[];
  stickers: EmoteEntity[];
  org?: PopulateRelationship<OrganizationRelation>;
};

export type EmotesByOrg = {
  [id: string]: undefined | OrgEmoteStickerList;
};

const defaultName = "NamiComi";

const categoryByKind: Record<EmojiKind, "emotes" | "stickers"> = {
  emoji: "emotes",
  sticker: "stickers",
};

let availableEmotes: null | ReturnType<typeof getAvailableEmotes> = null;
let unicodeEmotes: null | ReturnType<typeof createAsyncResource<string>> = null;
let favoriteEmotes: null | ReturnType<
  typeof createAsyncResource<EmoteFavoriteEntity[]>
> = null;

export function useAvailableEmotes() {
  if (!availableEmotes) availableEmotes = getAvailableEmotes();
  return availableEmotes;
}

export function useUnicodeEmotes() {
  if (!unicodeEmotes)
    unicodeEmotes = createAsyncResource<string>(async () => {
      const resp = await fetch("/static/emotes/emoji-test.txt");
      return await resp.text();
    });
  return unicodeEmotes;
}

export function useFavoriteEmotes() {
  const isLoggedIn = useIsLoggedIn();

  if (!favoriteEmotes) {
    favoriteEmotes = createAsyncResource<EmoteFavoriteEntity[]>(
      async (isLoggedIn: string) => {
        if (!isLoggedIn) return [];

        return await getAll<EmoteFavoriteEntity>(
          async (limit, offset) =>
            await Emote.getFavorites(
              {
                limit,
                offset,
                includes: ["emote"],
                order: { createdAt: "desc" },
              },
              await getTokenOrThrow(),
            ),
        );
      },
      computed(() => (isLoggedIn.value ? "true" : "")),
    );

    setInterval(() => favoriteEmotes?.value.refresh(), 1000 * 60 * 5);
  }

  const favoriteEmotesData = computed(() => favoriteEmotes!.value.data.value);
  const favoriteEmotesRelationships = computed(
    () =>
      favoriteEmotesData.value
        ?.map((favorite) => favorite.relationships.find(isEmote))
        .filter(onlyTruthys)
        .filter(isExpanded) ?? [],
  );
  const favoriteEmotesError = computed(() => favoriteEmotes!.value.error.value);
  const favoriteEmotesPending = computed(
    () => favoriteEmotes!.value.pending.value,
  );
  const favoriteEmotesRefresh = computed(() => favoriteEmotes!.value.refresh);

  const setFavorite = useAction2(
    async (emote: EmoteEntity | PopulateRelationship<EmoteRelation>) => {
      if (!isLoggedIn.value) return;
      const favorite = await Emote.setFavorite(
        emote.id,
        await getTokenOrThrow(),
      );

      const emoteRel = favorite.relationships.find(isEmote);
      if (emoteRel) emoteRel.attributes = emote.attributes;

      favoriteEmotesData.value?.unshift(favorite);
    },
  );

  const removeFavorite = useAction2(
    async (emote: EmoteEntity | PopulateRelationship<EmoteRelation>) => {
      if (!isLoggedIn.value) return;
      await Emote.removeFavorite(emote.id, await getTokenOrThrow());
      const index = favoriteEmotesData.value?.findIndex(
        (favorite) => favorite.relationships.find(isEmote)?.id === emote.id,
      );
      if (index !== undefined && index !== -1)
        favoriteEmotesData.value?.splice(index, 1);
    },
  );

  return {
    favoriteEmotesData,
    favoriteEmotesRelationships,
    favoriteEmotesError,
    favoriteEmotesPending,
    favoriteEmotesRefresh,
    setFavorite,
    removeFavorite,
  };
}

function getAvailableEmotes() {
  const availableEmotesAsyncResource = createAsyncResource<EmotesByOrg>(
    async () => {
      const emotes = await fetchAllAvailableEmotes();

      const emotesByOrg: EmotesByOrg = {
        [defaultName]: {
          emotes: [],
          stickers: [],
        },
      };

      emotes.forEach((emote) => {
        const org = emote.relationships.find(isOrg);
        const category = categoryByKind[emote.attributes.kind];
        if (!org || !isExpanded(org)) {
          emotesByOrg[defaultName]![category].push(emote);
        } else {
          if (org.id in emotesByOrg) {
            emotesByOrg[org.id]![category].push(emote);
          } else {
            emotesByOrg[org.id] = {
              emotes: [],
              stickers: [],
              org: org,
            };

            emotesByOrg[org.id]![category].push(emote);
          }
        }
      });

      return emotesByOrg;
    },
  );

  const availableEmotesData = computed(
    () => availableEmotesAsyncResource.value.data.value,
  );
  const availableEmotesError = computed(
    () => availableEmotesAsyncResource.value.error.value,
  );
  const availableEmotesPending = computed(
    () => availableEmotesAsyncResource.value.pending.value,
  );
  const availableEmotesRefresh = computed(
    () => availableEmotesAsyncResource.value.refresh,
  );

  return {
    availableEmotesData,
    availableEmotesError,
    availableEmotesPending,
    availableEmotesRefresh,
  };
}

async function fetchAllAvailableEmotes() {
  const emotes: EmoteEntity[] = [];

  let offset = 0;

  while (true) {
    const collection = await Emote.getAuthedUserEmotes(
      {
        includes: ["organization", "tier_item"],
        offset: offset,
        limit: 500,
      },
      await getTokenOrThrow(),
    );

    emotes.push(...collection.data);

    if (collection.meta.total <= offset + 500) {
      break;
    }

    offset += 500;
  }

  // TODO: Backend has a bug where it returns duplicates,
  // remove this once it's fixed
  return emotes.dedupe((emote) => emote.id);
}
