<template>
  <button
    class="p-1 rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700 transition-colors"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @click="$emit('select')"
    :title="emote.attributes.key"
  >
    <EmoteImage
      v-if="emote.attributes.kind === 'emoji'"
      :emote="emote"
      size="small"
    ></EmoteImage>
    <StickerImage v-else :sticker="emote"></StickerImage>
  </button>
</template>

<script setup lang="ts">
import type {
  EmoteEntity,
  EmoteRelation,
  PopulateRelationship,
} from "~/src/api";

const props = defineProps<{
  emote: EmoteEntity | PopulateRelationship<EmoteRelation>;
  shownDetails?: ("name" | "key" | "organization")[];
}>();

defineEmits<{
  (e: "select"): void;
  (e: "focus"): void;
  (e: "blur"): void;
}>();

const detailsToShow = computed(() => props.shownDetails ?? ["name", "key"]);
</script>
