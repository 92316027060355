<template>
  <button
    class="p-1 text-[2rem] leading-[2.125rem] flex justify-center items-center rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700 transition-colors"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @click="$emit('select')"
  >
    <p class="w-8 h-8">
      {{ character }}
    </p>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  name: string;
  character: string;
  shownDetails?: ("name" | "key")[];
}>();

defineEmits<{
  (e: "select"): void;
  (e: "focus"): void;
  (e: "blur"): void;
}>();

const detailsToShow = computed(() => props.shownDetails ?? ["name", "key"]);
</script>
